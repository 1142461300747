// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/mac/Personal/ITP/neurofit/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/mac/Personal/ITP/neurofit/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/mac/Personal/ITP/neurofit/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jr-js": () => import("/Users/mac/Personal/ITP/neurofit/src/pages/jr.js" /* webpackChunkName: "component---src-pages-jr-js" */),
  "component---src-pages-midterm-js": () => import("/Users/mac/Personal/ITP/neurofit/src/pages/midterm.js" /* webpackChunkName: "component---src-pages-midterm-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/mac/Personal/ITP/neurofit/.cache/data.json")

